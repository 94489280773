import * as React from 'react';
import { descend, map, path, sortWith } from 'ramda';
import { CampaignStatus } from 'types/campaign';
import { Season } from 'types/season';
import { seasonOrdinal } from 'utils/campaign';
import {
  CAMPAIGN_PLANNER_PATH,
  SEASONAL_DASHBOARD_PATH_PREFIX,
} from 'utils/paths';
import HoverTable from 'components/HoverTable';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import NewWindowLink from 'components/NewWindowLink';
import Loader from 'components/Loader';
import { Link } from 'react-router-dom';
import SponsorCampaignsTableRow from './SponsorCampaignsTableRow';

const sortWithYearSeason = sortWith([
  descend(path(['year'])),
  descend(seasonOrdinal),
]);

interface Campaign {
  id: number;
  magicToken: string;
  season: Season;
  status: CampaignStatus;
  year: string;
  name: string;
}

interface Props {
  campaigns: Campaign[];
  title: string;
  sponsorId: number;
  shouldRenderEditSeasonalDashboardButton: boolean;
}

export const query = gql`
  query Sponsor($id: Int!, $season: String!, $year: Int!) {
    sponsor(id: $id) {
      id
      magicToken(season: $season, year: $year)
    }
  }
`;

const SponsorCampaignsTable: React.FunctionComponent<Props> = ({
  campaigns,
  title,
  shouldRenderEditSeasonalDashboardButton,
  sponsorId,
}: Props) => {
  const sortedCampaigns = sortWithYearSeason(campaigns) as Campaign[];
  const getSeasonAndYear = () => {
    const seasonAndYear = title.split(' ');
    const season = seasonAndYear[0].toLowerCase();
    const year = parseInt(seasonAndYear[1], 10);

    return { season, year };
  };

  const { season, year } = getSeasonAndYear();
  const { data, loading } = useQuery(query, {
    variables: { id: sponsorId, season, year },
  });

  if (loading) return <Loader />;

  return (
    <>
      <div
        className="d-flex align-self-center mb-5-important"
        data-test="campaigns-table"
      >
        <div className="mr-auto">
          <strong>{title || 'Campaigns'}</strong>
        </div>
        {shouldRenderEditSeasonalDashboardButton ? (
          <div className="ml-auto">
            {campaigns.length > 1 && (
              <>
                <NewWindowLink
                  hideIcon
                  className="mr-1"
                  to={`${SEASONAL_DASHBOARD_PATH_PREFIX}/${data.sponsor.magicToken}`}
                >
                  <button className="btn btn-sm btn-primary mr-1" type="button">
                    View Seasonal Dashboard
                  </button>
                </NewWindowLink>
                <Link
                  className="btn btn-sm btn-primary ml-1"
                  to={`${CAMPAIGN_PLANNER_PATH}/${sponsorId}/seasonal-goal/${season}/${year}`}
                >
                  Edit Seasonal Dashboard
                </Link>
              </>
            )}
          </div>
        ) : (
          campaigns.length > 1 && (
            <NewWindowLink
              hideIcon
              className="ml-auto"
              to={`${SEASONAL_DASHBOARD_PATH_PREFIX}/${data.sponsor.magicToken}`}
            >
              <button className="btn btn-sm btn-primary ml-auto" type="button">
                View All Seasonal Data
              </button>
            </NewWindowLink>
          )
        )}
      </div>
      <HoverTable>
        <thead>
          <tr>
            <th scope="col">Campaign Name</th>
            <th scope="col">Details</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {map(
            (campaign) => (
              <SponsorCampaignsTableRow key={campaign.id} campaign={campaign} />
            ),
            sortedCampaigns,
          )}
        </tbody>
      </HoverTable>
    </>
  );
};

export default SponsorCampaignsTable;
